import { useTranslation } from 'react-i18next'
import React from 'react'

import Icon from './icon'
import { useCurrentUser } from '../hooks/useCurrentUser'

function Footer() {
  const { t } = useTranslation()
  const { locale } = useCurrentUser()

  return (
    <div className="footer mt-auto position-relative bottom-0 w-100">
      <div className="mainfooter">
        <div className="container">
          <div className="row">
            <div className="col">
              <p>
                <a
                  className="footer-btn btn btn-link"
                  href={
                    locale === 'es'
                      ? 'https://civicrush.com/acuerdo-de-la-plataforma/'
                      : 'https://civicrush.com/privacy-policy/'
                  }
                  target="_blank"
                  rel="noreferrer"
                >
                  {t('footer_banner.privacy_policy')}
                </a>
              </p>
            </div>
            <div className="col">
              <p>
                <a
                  className="footer-btn btn btn-link"
                  href={
                    locale === 'es'
                      ? 'https://civicrush.com/terminos-de-servicio/'
                      : 'https://civicrush.com/terms-of-service/'
                  }
                  target="_blank"
                  rel="noreferrer"
                >
                  {t('footer_banner.terms')}
                </a>
              </p>
            </div>
            <div className="col">
              <p>
                <a
                  className="footer-btn btn btn-link"
                  href={
                    locale === 'es'
                      ? 'https://civicrush.com/ayuda/'
                      : 'https://civicrush.com/help-and-support/'
                  }
                  target="_blank"
                  rel="noreferrer"
                >
                  {t('footer_banner.help')}
                </a>
              </p>
            </div>
            <div className="col">
              <p>
                <a
                  className="footer-btn btn btn-link"
                  href={
                    locale === 'es'
                      ? 'https://civicrush.com/faq-es/'
                      : 'https://civicrush.com/faq/'
                  }
                  target="_blank"
                  rel="noreferrer"
                >
                  {t('footer_banner.FAQ')}
                </a>
              </p>
            </div>
            <div className="col">
              <p>
                <a
                  className="footer-btn btn btn-link"
                  href="https://civicrush.com/stories/"
                  target="_blank"
                  rel="noreferrer"
                >
                  {t('footer_banner.stories')}
                </a>
              </p>
            </div>
            <div className="col social">
              <a
                className="btn btn-link"
                target="_blank"
                href="https://facebook.com/civicrush"
                rel="noreferrer"
              >
                <Icon name="facebook" type="brands" />
              </a>
              <a
                className="btn btn-link"
                target="_blank"
                href="https://instagram.com/civicrush"
                rel="noreferrer"
              >
                <Icon name="instagram" type="brands" />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="subfooter">
        <div className="container">
          <div className="row">
            <div className="col">
              <p>
                &copy;
                {new Date().getFullYear()} Metaformers, Inc
                <span className="version">
                  {' '}
                  - v{process.env.REACT_APP_VERSION} (
                  {process.env.REACT_APP_CODEBUILD_BUILD_NUMBER})
                </span>
                <span className="dev">
                  BUILD_ID: {process.env.REACT_APP_CODEBUILD_BUILD_ID}
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer

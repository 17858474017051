import React, { useEffect, useState } from 'react'
import {
  ContentState,
  convertToRaw,
  EditorState,
  convertFromHTML,
} from 'draft-js'
import { Editor, stateFromMarkdown } from 'react-draft-wysiwyg'
import { convertToHTML } from 'draft-convert'

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { isHTML } from '../lib/strings'
import { useCurrentUser } from '../hooks/useCurrentUser'
import Icon from './icon'

export default function TextareaEditor({ text, onChangeText }) {
  const { locale } = useCurrentUser()
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty(),
  )

  useEffect(() => {
    if (!!text.length) {
      let content
      if (isHTML(text)) {
        const blocksFromHTML = convertFromHTML(text)
        content = ContentState.createFromBlockArray(
          blocksFromHTML.contentBlocks,
          blocksFromHTML.entityMap,
        )
      } else {
        content = ContentState.createFromText(text)
      }
      const raw = EditorState.createWithContent(content)
      setEditorState(raw)
    }
  }, [])

  useEffect(() => {
    if (editorState && editorState.getCurrentContent()) {
      let html = convertToHTML({
        entityToHTML: (entity, originalText) => {
          if (entity.type === 'LINK') {
            return (
              <a href={entity.data.url} target="_blank">
                {originalText}
              </a>
            )
          }
          return originalText
        },
      })(editorState.getCurrentContent())
      onChangeText(html)
    }
  }, [editorState])

  return (
    <Editor
      editorState={editorState}
      onEditorStateChange={setEditorState}
      wrapperClassName="wrapperEditor"
      editorClassName="editorEditor"
      toolbarClassName="toolbarEditor"
      localization={{
        locale: locale,
      }}
      toolbar={{
        options: ['history', 'inline', 'list', 'remove', 'link', 'emoji'],
        inline: {
          options: ['bold', 'italic', 'underline'],
        },
        list: {
          options: ['unordered', 'ordered'],
        },
      }}
    />
  )
}

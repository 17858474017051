import PropTypes from 'prop-types'
import React, { useState, useEffect } from 'react'
import { Card, Carousel } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import 'moment/locale/es'
import moment from 'moment-timezone'

import styles from './event-card.module.scss'
import { isVideo } from '../lib/files'
import { useCurrentUser } from '../hooks/useCurrentUser'
import Button from './button'
import Icon from './icon'
import { formatLocation } from '../lib/address'
import { sortCategoriesAlphabetically } from '../lib/sort'

export default function EventCard({ event }) {
  const { t } = useTranslation()
  const { locale } = useCurrentUser()

  const formattedLocation = formatLocation(event?.location || null)

  useEffect(() => {
    moment.locale(locale)
  }, [locale])

  const thumbnailUrl =
    event.media_attachments?.[0]?.thumbnail_url ||
    '/images/thumbnail-placeholder.png'
  const directUploadUrl =
    event.media_attachments?.[0]?.direct_upload_url ||
    '/images/thumbnail-placeholder.png'

  const startMoment = moment(event?.event_start_date).tz(moment.tz.guess())
  const endMoment = moment(event?.event_end_date).tz(moment.tz.guess())
  const sameDay = startMoment.isSame(endMoment, 'day')

  return (
    <div className="col-md-4">
      <Card className={styles.card}>
        {(!!event?.media_attachments?.length && (
          <Carousel controls>
            {event?.media_attachments?.map(imgLink => {
              return (
                <Carousel.Item
                  key={`${event?.id} ${imgLink.id}`}
                  className="card-img-top"
                >
                  {(isVideo(imgLink) && (
                    <video
                      className="card-img-top"
                      autoPlay
                      muted
                      loop
                      controls
                      poster={imgLink.thumbnail_url}
                      src={imgLink.direct_upload_url}
                    />
                  )) || (
                    <Card.Img
                      variant="top"
                      src={imgLink.direct_upload_url}
                      alt={t('event.media_post_video')}
                    />
                  )}
                </Carousel.Item>
              )
            })}
          </Carousel>
        )) || (
          <Card.Img
            variant="top"
            src={
              locale === 'es'
                ? '/images/draft-media-placeholder-ES.png'
                : '/images/draft-media-placeholder-EN.png'
            }
            alt={t('event.media_post_thumbnail')}
          />
        )}
        <Card.Body>
          <div className={styles.card_badges}>
            <div>
              {event.drafted_at && (
                <span className="badge badge-yellow">
                  {t('event_card.draft')}
                </span>
              )}
              {!!event.event_end_date &&
                !!moment(event.event_end_date).isBefore(moment()) && (
                  <span className="badge badge-success">
                    {t('event_card.past')}
                  </span>
                )}
              {(event.current_user_admin || event.is_user_admin) && (
                <span className="badge badge-purple">
                  {t('event_card.admin')}
                </span>
              )}
            </div>
          </div>
          <Card.Title className="card_title">{event.title}</Card.Title>
          {!!event.service_hours && (
            <h6 className="card_subtitle">
              <Icon name="user-clock" /> {t('event_card.has_service_hours')}
            </h6>
          )}
          {event.event_start_date && (
            <>
              <h6 className="card_subtitle">
                <Icon name="calendar" />
                {startMoment.format(t('config.date_format'))}{' '}
                {!sameDay && (
                  <>
                    {t('event_card.to')}{' '}
                    {endMoment.format(t('config.date_format'))}
                  </>
                )}
              </h6>
              <h6 className="card_subtitle">
                <span>
                  <Icon name="clock" /> {startMoment.format('h:mm a')}
                </span>
                <span>
                  {' '}
                  {t('event_card.to')} {endMoment.format('h:mm a z')}
                </span>
              </h6>
            </>
          )}
          {event?.remote && (
            <h6 className="card_subtitle">
              <Icon name="laptop-house" />
              {t('event.remote')}
            </h6>
          )}
          {!event?.remote && formattedLocation && (
            <h6 className="card_subtitle">
              <Icon name="map-marker-alt" />{' '}
              <a
                href={`https://www.google.com/maps/search/?api=1&query=${encodeURI(
                  formattedLocation,
                )}`}
                target="_blank"
                rel="noreferrer"
              >
                {formattedLocation}
              </a>
            </h6>
          )}
          {!!event?.categories?.length && (
            <h6 className="card_subtitle">
              <Icon name="tags" />{' '}
              {sortCategoriesAlphabetically(event.categories, locale)
                .map(category =>
                  locale === 'es' ? category.es_name : category.en_name,
                )
                .join(', ')}
            </h6>
          )}
          <h6 className="card_subtitle">
            {event.is_private && (
              <>
                <Icon name="lock" /> {t('event_card.private')}
              </>
            )}
            {!event.is_private && (
              <>
                <Icon name="globe-americas" /> {t('event_card.public')}
              </>
            )}
          </h6>
          <div className={styles.stats}>
            {!!event.volunteers_needed && event.volunteers_needed > 0 && (
              <span className={styles.card_link}>
                <Icon name="hand-paper" /> {event.volunteers_count ?? 0}{' '}
                {t('event_card.of')} {event.volunteers_needed}
              </span>
            )}
            <span className={styles.card_link}>
              <Icon name="heart" /> {event.cached_votes_total ?? 0}
            </span>
            <span className={styles.card_link}>
              <Icon name="share" /> {event.share_count ?? 0}
            </span>
          </div>
          <div className="row">
            <div className="col-6">
              <Link to={`/events/${event.slug ?? event.id}/edit`}>
                <Button className="w-100">{t('event.edit_post')}</Button>
              </Link>
            </div>
            <div className="col-6">
              <Link target="_blank" to={`/events/${event.slug ?? event.id}`}>
                <Button className="w-100">{t('event_card.view_post')}</Button>
              </Link>
            </div>
          </div>
        </Card.Body>
      </Card>
    </div>
  )
}

EventCard.propTypes = {
  event: PropTypes.object.isRequired,
}
